<template>
  <div class="main">
    <back />
    <!-- 搜索框 -->
    <div>
      <div class="search-right">
        <template v-if="this.$route.query.id">
          <el-input v-model="productName" placeholder="输入商品名称" @change="onCurrentPage" clearable></el-input>
          <el-input v-model="barCode" placeholder="输入商品条形码" @change="onCurrentPage" clearable></el-input>
          <el-cascader ref="radio" placeholder="请选择分类" v-model="categoryIds" :props="{ checkStrictly: true }"
            :options="optionsList" @change="handleListChange" clearable filterable></el-cascader>
        </template>
        <el-button type="primary" @click="addProduct">加入商品</el-button>
      </div>
      <!-- 表格 -->
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="进货套餐名称：" prop="packageName">
                <el-input v-model.trim="ruleForm.packageName" placeholder="请输入进货套餐名称" clearable
                  @change="handlePackageName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="this.$route.query.id">
              <el-button type="primary" @click="submitForm('ruleForm')">修改进货套餐名称</el-button>
            </el-col>
          </el-row>
          <el-form-item label-width="0px">
            <div class="globle_table">
              <el-table :data="tableData" style="width: 100%" max-height="540">
                <el-table-column prop="number" label="序号" min-width="60" align="center">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="serialId" label="商品编号">
                </el-table-column> -->
                <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
                </el-table-column>
                <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
                </el-table-column>
                <el-table-column prop="specs" label="商品规格" show-overflow-tooltip min-width="110" align="center">
                </el-table-column>
                <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip min-width="130" align="center">
                </el-table-column>
                <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100" align="center">
                </el-table-column>
                <!-- <el-table-column prop="factoryName" label="生产厂家">
                </el-table-column> -->
                <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="120" align="center">
                </el-table-column>
                <el-table-column prop="validityPeriod" label="保质期(天)" show-overflow-tooltip min-width="130"
                  align="center">
                </el-table-column>
                <el-table-column prop="purchaseBox" label="建议进货箱数" show-overflow-tooltip min-width="150" align="center">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.purchaseBox" placeholder="请输入进货箱数" @focus="onFocus(scope.row)"
                      @change='updatePurchasePackageProduct(scope.row)'></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作" header-align="center" show-overflow-tooltip min-width="110" align="center"
                  fixed="right">
                  <template slot-scope="scope">
                    <el-button class="btn" v-if="$route.query.id" type="danger" size="small" plain
                      @click="handleEditDel(scope.row)">删除</el-button>
                    <el-button class="btn" v-else type="danger" size="small" plain
                      @click="handleDel(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
        <div style="text-align: center;" v-if="!this.$route.query.id">
          <el-button type="primary" @click="submitForm('ruleForm')">确定提交</el-button>
        </div>
      </div>
      <!-- 页面分页 -->
      <el-pagination v-if="this.$route.query.id" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize"
        :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
    <!-- 商品弹窗 -->
    <el-dialog title="新增商品包数据" :visible.sync="dialogTableVisible">
      <div class="search-right">
        <el-input v-model="aPopoverProductName" placeholder="请输入商品名称" @change="onAPopoverCurrentPage"
          clearable></el-input>
        <el-input v-model="aPopoverBarCode" placeholder="请输入商品条形码" @change="onAPopoverCurrentPage" clearable></el-input>
        <el-cascader placeholder="请选择分类" v-model="aPopoverCategoryId" :props="{ checkStrictly: true }"
          :options="options" @change="handleChange" clearable filterable></el-cascader>
        <!-- <el-button type="primary" @click="changeAll">全 选</el-button> -->
      </div>
      <div class="globle_table" v-loading="aPopoverLoading" element-loading-text="玩命加载中">
        <el-table :data="gridData" max-height="540" ref="multipleTable" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="130">
          </el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="140" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="factoryName" label="商品厂家" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="categoryName" label="分类名称" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="商品规格" show-overflow-tooltip min-width="110" align="center" fixed="right">
          </el-table-column>
        </el-table>
      </div>
      <!-- 商品弹窗分页 -->
      <div class="pagination">
        <el-pagination background @size-change="handleAPopoverSizeChange" @current-change="handleAPopoverCurrentChange"
          :current-page.sync="aPopoverCurrentPage" :page-size="aPopoverPageSize" :page-sizes="aPopoverPageSizeArr"
          layout="prev, pager, next, jumper, sizes, total" :total="aPopoverTotalCount">
        </el-pagination>
      </div>
      <div
        style="text-align: center;margin-top: 15px; display: flex;align-items: center; justify-content: center; flex-direction: column;">
        <div style="color: red;margin-bottom: 10px;">* 温馨提示：翻页前，请先提交当前页的数据</div>
        <div>
          <el-button @click="dialogTableVisible = false">关闭弹窗</el-button>
          <el-button class="btns" type="primary" @click="handleSettlement"
            :disabled="multipleSelection.length == 0">确定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表格展示
      ruleForm: {
        packageName: '',
      },
      // 新增表格数据显示
      addRuleForm: {},
      dialogTableVisible: false,//添加商品弹窗显示
      gridData: [],//添加商品弹窗数据显示
      aPopoverProductName: "",//弹窗商品名称搜索
      aPopoverBarCode: "",//弹窗商品条形码搜索
      aPopoverCategoryId: null,//弹窗商品分类搜索
      categoryIds: null,//商品分类搜索
      productName: "",//商品名称搜索
      barCode: "",//商品条形码搜索
      tableData: [],//表格数据
      rules: {
        packageName: [
          { required: true, message: '请输进货套餐名称', trigger: 'blur' },
        ],
      },
      // 分类查询
      options: [],
      optionsList: [],//列表分类
      aPopoverLoading: false,//弹窗商品数据加载
      input: 0,
      classificationType: '',//分类
      brand: "",  // 品牌
      newtableLabel: [],//空数组
      multipleSelection: [], // 选中数据
      // 弹窗分页数据
      aPopoverCurrentPage: 1, // 当前页码
      aPopoverPageSize: 10, // 每页显示的行数
      aPopoverPageSizeArr: [30, 50, 100, 200, 300], // 每页显示的行数(可选)
      aPopoverTotalCount: 0, // 总记录数（需要从后端获取）
      // 编辑页面分页数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [5, 10, 20, 30], // 每页显示的行数(可选)
      totalCount: 0, // 总记录数（需要从后端获取）
      disabled: true,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getSelectPurchasePackageProduct()//编辑商品列表
      this.getSelectProductList()//弹窗商品列表
    } else {
      this.getProductList()//弹窗商品列表
    }
    this.getCategoryList()//分类列表
  },
  destroyed() {
    localStorage.removeItem('temporarilyPackageName')
  },
  methods: {
    // 编辑商品信息
    getSelectPurchasePackageProduct() {
      this.dataLoding = true;
      const params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        auditStatus: 1,
        packageId: this.$route.query.id,//商品包id
        productName: this.productName || null,//商品名称
        categoryIds: this.categoryIds || null,//商品分类
        barCode: this.barCode || null,//商品条形码
      }
      this.$axios
        .post(this.$api.selectPurchasePackageProduct, params)
        .then((res) => {
          this.dataLoding = false;
          if (localStorage.getItem('temporarilyPackageName')) {
            this.ruleForm.packageName = localStorage.getItem('temporarilyPackageName')//获取用户新修改的名称
          } else {
            this.ruleForm.packageName = this.$route.query.packageName
          }

          this.tableData = res.data.result.list;
          this.totalCount = res.data.result.totalCount;
        });
    },
    // 编辑商品时加入新商品
    getInsertPurchasePackageProduct() {
      let list = this.multipleSelection.map((item) => ({
        productId: item.productId
      }));
      let data = {
        id: this.$route.query.id,
        // packageName: this.$route.query.packageName,//编辑时加入新商品不做包名修改
        productCount: this.totalCount,//商品总数
        productList: list,//商品数据
      }
      this.$axios.post(this.$api.insertPurchasePackageProduct, data)
        .then((res) => {
          if (res.data.code == 100) {
            this.getSelectPurchasePackageProduct()//编辑商品列表
          }
        });
    },
    // 弹窗商品列表
    getProductList() {
      this.aPopoverLoading = true;
      this.$axios
        .get(this.$api.productList, {
          params: {
            page: this.aPopoverCurrentPage,
            pageSize: this.aPopoverPageSize,
            auditStatus: 1,
            // serialId: this.serialId || null,
            productName: this.aPopoverProductName || null,//商品名称
            categoryId: this.aPopoverCategoryId || null,//商品分类
            barCode: this.aPopoverBarCode || null, // 商品条形码
            // selectType: 1,
          },
        })
        .then((res) => {
          this.aPopoverLoading = false;
          this.gridData = res.data.result.list;
          this.aPopoverTotalCount = res.data.result.totalCount;
        });
    },
    // 弹窗商品列表 (编辑时)
    getSelectProductList() {
      this.dataLoding = true;
      this.$axios
        .get(this.$api.selectProductList, {
          params: {
            page: this.aPopoverCurrentPage,
            pageSize: this.aPopoverPageSize,
            auditStatus: 1,
            packageId: this.$route.query.id,//商品包名称
            productName: this.aPopoverProductName || null,//商品名称
            categoryIds: this.aPopoverCategoryId || null,//商品分类
            barCode: this.barCode || null,//商品条形码
            // selectType: 1,
          },
        })
        .then((res) => {
          this.dataLoding = false;
          this.gridData = res.data.result.list;
          this.aPopoverTotalCount = res.data.result.totalCount;
        });
    },
    // 弹窗分类列表获取
    getCategoryList() {
      this.dataLoding = true;
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            // page: this.currentPage,
            pageSize: 9999,
            auditStatus: 1,
            // categoryName: this.categoryName || null,
          },
        })
        .then((res) => {
          const { code, result } = res.data
          if (code == 100) {
            // 弹窗分类
            this.options = result.list.map((item) => ({
              label: item.categoryName,
              value: item.categoryId,
              children: item.children.map((child) => ({
                label: child.categoryName,
                value: child.categoryId
              }))
            }));
            // 列表分类
            this.optionsList = result.list.map((item) => ({
              label: item.categoryName,
              value: item.categoryId,
              children: item.children.map((child) => ({
                label: child.categoryName,
                value: child.categoryId
              }))
            }));
          }
        });
    },
    // 套餐内分类列表
    // changeAll() {
    //   console.log('全选按钮');
    // },
    // 暂时存改变的名称
    handlePackageName(e) {
      // console.log('暂时存改变的名称temporarilyPackageName', e);
      localStorage.setItem('temporarilyPackageName', e);
    },
    // 提交数据
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.tableData.length > 0) {
          let productList;   // 商品id汇总
          let data;   // 上传的数据
          if (this.$route.query.id) {
            // 编辑数据
            productList = this.tableData.map((item) => {
              return {
                productId: item.productId,
                purchaseBox: item.purchaseBox
              }
            })
            data = {
              id: this.$route.query.id,//新增不传，编辑传
              packageName: this.ruleForm.packageName,
              productCount: this.totalCount,//新增不传，编辑传 外部按钮不做商品数量更改
              // productList: productList//商品信息 外部按钮不对商品数据更改
            }
            this.$axios
              .post(this.$api.updatePurchasePackage, data)
              .then((res) => {
                const { code } = res.data
                if (code == 100) {
                  this.$message({
                    type: 'success',
                    message: '修改成功!'
                  });
                  this.$router.go(-1)
                  this.resetForm()
                }
              });
          } else {
            // 新增数据
            // 商品id汇总
            productList = this.tableData.map((item) => {
              return {
                productId: item.productId,
                purchaseBox: item.purchaseBox
              }
            })
            data = {
              packageName: this.ruleForm.packageName,
              productList: productList//商品信息
            }
            this.$axios
              .post(this.$api.insertPurchasePackage, data)
              .then((res) => {
                const { code } = res.data
                if (code == 100) {
                  this.$message({
                    type: 'success',
                    message: '新增成功!'
                  });
                  this.$router.go(-1)
                  this.resetForm()
                }
              });
          }
        } else {
          if (valid && this.tableData.length <= 0) {
            this.$message.error('请添加商品后再提交！');
          }
          return false;
        }
      });
    },
    // 校验重置
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.ruleForm = {}
      this.tableData = []
    },
    // 添加商品按钮
    addProduct() {
      this.getSelectProductList()
      this.dialogTableVisible = true
    },
    // 编辑时获取当条修改建议箱数数据
    onFocus(row) {
      console.log(row.purchaseBox);
      console.log(row.productId);

    },
    // 编辑时修改建议箱数请求
    updatePurchasePackageProduct(row) {
      if (this.$route.query.id) {
        const data = {
          purchaseBox: row.purchaseBox,
          productId: row.productId,
          id: row.id,
        }
        this.$axios.post(this.$api.updatePurchasePackageProduct, data).then((res) => {
          const { code } = res.data
          if (code == 100) {
            this.getSelectPurchasePackageProduct()//编辑商品列表
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
          }
        });
      }

    },
    // 页面商品数据删除
    handleDel(row) {
      const index = this.tableData.findIndex(item => item.productId === row.productId);
      this.$confirm('此操作将删除该商品信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        if (index !== -1) {
          this.tableData.splice(index, 1);
          this.$refs.multipleTable.toggleRowSelection(row, false);  // 取消勾选删除的行
          // this.$refs.multipleTable.toggleRowSelection(row);  // 不传false则勾选
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 页面商品数据编辑删除
    handleEditDel(row) {
      const url = `${this.$api.deletePurchasePackageProduct}/${row.id}`
      this.$confirm('此操作将删除该商品信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(url).then((res) => {
          const { code } = res.data
          if (code == 100) {
            this.getSelectPurchasePackageProduct()//编辑商品列表
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 选中弹窗的数据
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return {
          ...item,
          purchaseBox: 1,
        }
      });
      console.log(this.multipleSelection);

    },
    // 确定
    handleSettlement() {
      if (this.$route.query.id) {
        this.getInsertPurchasePackageProduct()//编辑时调用接口加入列表
      } else {
        // 添加数据中有相同数据则不添加
        this.multipleSelection.forEach((item) => {
          const found = this.tableData.find(dataItem => dataItem.productId === item.productId);
          if (!found) {
            this.tableData.push(item);
          }
        });
      }

    },
    // 弹窗分类筛选
    handleChange(value) {

      this.aPopoverCategoryId = value[1] || value[0] || null
      console.log(this.aPopoverCategoryId);
      this.onAPopoverCurrentPage()
    },
    // 编辑页面分类
    handleListChange(value) {
      this.categoryIds = value
      this.getSelectPurchasePackageProduct()//编辑商品列表
    },
    // 换页
    handleCurrentChange(e) {
      this.page = e;
      this.getSelectPurchasePackageProduct()//编辑商品列表
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onCurrentPage();//列表请求
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getSelectPurchasePackageProduct()//编辑商品列表
    },
    // 弹窗分页初始
    onAPopoverCurrentPage() {
      this.gridData = [];
      this.aPopoverCurrentPage = 1
      this.getProductList()
    },
    // 弹窗分页换页
    handleAPopoverCurrentChange(val) {
      this.aPopoverCurrentPage = val;
      this.getProductList()
    },
    // 弹窗分页切换每页显示的条数
    handleAPopoverSizeChange(e) {
      this.aPopoverPageSize = e;
      this.onAPopoverCurrentPage()
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 20px 30px;

  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    ::v-deep .el-pagination__jump {
      margin-left: 7px;
    }

    ::v-deep .el-input {
      margin-right: 5px;
    }

    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 80px;
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox__input::after {
    content: '当前页全选';
    position: absolute;
    margin-left: 10px;
    color: rgba(3, 16, 14, 0.9);
    font-weight: 600;
  }

  .back {
    padding: 0px !important;
  }

  .search-box {
    font-size: 16px;
    margin: 30px 0px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }

  .search-right,
  .el-dialog__body {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .el-input,
    .el-select {
      width: 180px;
      margin-left: 10px;
      margin-bottom: 20px;
    }

    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 180px;
    }

    .el-button {
      margin-left: 10px;
      margin-bottom: 20px;
    }

    ::v-deep .el-cascader {
      width: 200px;
      margin-left: 10px;

      .el-input {
        width: 200px;
      }

      .el-input__inner {
        width: 200px;
      }
    }

  }

}
</style>